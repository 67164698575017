import { AnchorLink } from "gatsby-plugin-anchor-links"

import React from "react"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { Fragment } from "react"
import logoObWhite from "../images/logo-orbital-color.svg"

function Header({ siteTitle }) {
  const navigation = [
    { name: "About us", to: "/#about-us" },
    { name: "Spots", to: "/#spots" },
    { name: "How it works", to: "/#how-it-works" },
    { name: "Contact", to: "/#contact-us" },
  ]

  return (
    <nav id="navbar" className="py-4 mx-auto container flex self-center w-full">
      <Popover className="w-full ">
        {({ open }) => (
          <>
            <div className=" relative z-50 flex justify-evenly items-center p-2 md:p-6 w-full flex-grow">
              <div className="px-3 md:px-0 flex items-center justify-between w-full md:w-auto">
                <div className="relative text-white text-2xl">
                  <span className="sr-only">{siteTitle}</span>
                  <img
                    className="h-9 w-auto self-center"
                    src={logoObWhite}
                    alt="OrbitalBase logo"
                  />
                </div>
                {/* HAMBURGER MENU (CLOSED) */}
                <div className="mr-2 md:hidden">
                  <Popover.Button className="z-50 bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-700 focus:outline-none ">
                    <span className="sr-only">Open navigation menu</span>
                    <MenuIcon className="h-6 w-6" />
                  </Popover.Button>
                </div>
              </div>
              {/* NAV MENU */}
              <div className="flex items-center flex-1 justify-end h-full w-1/2 self-end">
                <ul className="hidden md:flex md:gap-8 justify-between w-6/12">
                  {navigation.map(navlink => (
                    <li className="justify-center" key={navlink.name}>
                      <AnchorLink
                        to={navlink.to}
                        className={`
                        transition duration-250 ease-in-out
                        text-base
                        hover:text-orbitext
                        focus:text-orbitext
                        cursor-pointer
                        ${
                          navlink.name === "Contact"
                            ? " text-orbitext "
                            : " text-gray-200 "
                        }
                        `}
                      >
                        {navlink.name}
                      </AnchorLink>
                    </li>
                  ))}
                </ul>
              </div>
              {/* NAV MENU */}
            </div>

            {/* HAMBURGER MENU (EXPANDED) */}
            <Transition
              show={open}
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="z-50 absolute top-1 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md overflow-hidden bg-black">
                  <div className="px-5 pt-4 flex items-center justify-between text-orbitalBlue font-bold">
                    <img
                      className="h-6 w-auto self-center"
                      src={logoObWhite}
                      alt="OrbitalBase logo"
                    />
                    <div className="-mr-2">
                      <Popover.Button className="bg-black hover:bg-orbitext rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-white focus:ring-2 focus:ring-inset focus:ring-orbitext">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <ul className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map(item => (
                      <li key={item.name}>
                        <a
                          href={item.to}
                          className="block px-3 py-4 rounded-md text-base font-medium text-orbitext hover:text-gray-100 hover:bg-orbitext"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Popover.Panel>
            </Transition>
            {/* TOGGLED HAMBURGER MENU ITEMS */}
          </>
        )}
      </Popover>
    </nav>
  )
}

export default Header
