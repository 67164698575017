import React, { createContext, useState } from "react"

const MyProvider = props => {
  //This state will be shared between those props.children components
  const [state, setState] = useState({
    name: "Lima",
    countryCode: "PE",
    country: "Peru",
    image:
      "https://images.unsplash.com/photo-1585318822499-a5a2aa250b4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1675&q=80",
    active: true,
    googleMapsLink:
      "https://www.google.com/maps/place/12%C2%B005'12.9%22S+77%C2%B000'45.6%22W/@-12.0868822,-77.0214117,15z/data=!3m1!4b1!4m5!3m4!1s0x0:0xf15c9fa6e165cd73!8m2!3d-12.0869033!4d-77.0126569",
    highlight: "ISP Provider",
    address: "Carlos Krumdieck 287",
    latitude: -12.0869033,
    longitude: -77.0126569,
    geometry: { type: "Point", coordinates: [-12.0869033, -77.0126569] },
    spots: [
      {
        code: "LIM",
        city: "Lima",
        address: "Carlos Krumdieck 287",
        googleMapsLink:
          "https://www.google.com.uy/maps/place/33%C2%B043'52.5%22S+56%C2%B019'07.2%22W/@-33.731236,-56.318676,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xdd59be00dd0d09be!8m2!3d-33.731236!4d-56.318676",
        distanceToAirport: "8,0",
        latitude: -12.0869033,
        longitude: -77.0126569,
        geometry: {
          type: "Point",
          coordinates: [-12.0869033, -77.0126569],
        },
      },
    ],
  })

  const [selectedSpot, setSelectedSpot] = useState({
    code: "LIM",
    city: "Lima",
    country: "Peru",
    address: "Carlos Krumdieck 287",
    googleMapsLink:
      "https://www.google.com.uy/maps/place/33%C2%B043'52.5%22S+56%C2%B019'07.2%22W/@-33.731236,-56.318676,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xdd59be00dd0d09be!8m2!3d-33.731236!4d-56.318676",
    distanceToAirport: "8,0",
    latitude: -12.0869033,
    longitude: -77.0126569,
    geometry: {
      type: "Point",
      coordinates: [-12.0869033, -77.0126569],
    },
  })

  const [selectedSpotName, setSelectedSpotName] = useState("Lima (LIM)")

  return (
    <AppContext.Provider
      value={{
        state,
        setState,
        selectedSpot,
        setSelectedSpot,
        selectedSpotName,
        setSelectedSpotName,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default MyProvider
export const AppContext = createContext()
