import React from "react"
import CXLogo from "../images/logo-conexum.svg"
import ObIsotype from "../images/favicon.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  const navigation = [
    { name: "About us", to: "/#about-us" },
    { name: "Spots", to: "/#spots" },
    { name: "How it works", to: "/#how-it-works" },
    { name: "Contact", to: "/#contact-us" },
  ]

  return (
    <footer className="container mx-auto text-xs relative z-50 opacity-100">
      <div className="border-t border-white gap-4 h-32 p-4 border-opacity-50 w-full flex flex-col md:flex-row justify-between">
        <div className="flex gap-2 md:gap-6 justify-around">
          <AnchorLink
            to={"/#navbar"}
            className="h-8 w-8 self-center opacity-50 hover:opacity-100 transition-all duration-500 cursor-pointer"
          >
            <img src={ObIsotype} alt="OrbitalBase logo isotype" />
          </AnchorLink>
          <p className="text-gray-400 self-center">
            Copyright &copy; {year}, Orbital Base, Inc. All rights reserved.
          </p>
        </div>
        <div className="flex gap-3 justify-around">
          <div className="flex gap-6 text-center text-gray-400 self-center justify-between md:pl-6 w-full">
            <div className="flex gap-3 order-2 md:order-1 text-center self-center justify-between flex-1">
              {navigation.map(navLink => (
                <AnchorLink
                  key={navLink.name}
                  to={navLink.to}
                  className="                        
                  transition duration-250 ease-in-out
                  text-sm
                  font-thin
                  hover:text-orbitext
                  focus:text-orbitext
                  text-gray-400"
                >
                  {navLink.name}
                </AnchorLink>
              ))}
            </div>
            <a
              href="https://www.conexum.co/"
              target="_blank"
              rel="noreferrer"
              className="h-8 w-8 self-center order-1 md:order-2 opacity-50 hover:opacity-100 transition-all duration-500 cursor-pointer"
            >
              <img
                className="h-8 w-8 self-center opacity-50 hover:opacity-100 transition-all duration-500 cursor-pointer"
                src={CXLogo}
                alt="Conexum logo"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
